<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="5"/>
    <!-- 身体 -->
    <div class="main">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top"
        @mouseover="carouselMouseover" @mouseout="carouselMouseout">
        <el-carousel height="520px" arrow="never" @prev="prevs" @next="nexts" indicator-position="none" ref="carousel">
          <el-carousel-item v-for="(item, index) in list" :key="index">
            <img :src="item.image" class="banner_img" alt />
            <div class="banner_box">
              <div class="banner_box_l">
                <div class="banner_title">{{ item.title }}</div>
                <div class="banner_border"></div>
                <div class="banner_txt">{{ item.params }}</div>
                <div :class="isCarousel ? 'banner_btn' : 'banner_btns'">
                  <el-button @click="prevs">
                    <img src="../../assets/imgs/b_l.png" class="banner_btn_img" />
                  </el-button>
                  <el-button @click="nexts">
                    <img src="../../assets/imgs/b_r.png" class="banner_btn_img" />
                  </el-button>
                </div>
              </div>
              <!-- <div class="banner_box_r">
                <div>YANMEI ERA</div>
                <div>颜美时代</div>
              </div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 类型 -->
      <div class="type wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="type_l">全部类型</div>
        <div class="type_r">
          <div @click="typeClick(index, item.id)" :class="index == tabIndex ? 'type_r_active' : 'type_r_tab'"
            v-for="(item, index) in list1" :key="index">{{ item.title }}</div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="case wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <div class="case_top_box">
          <!-- 左边一个 -->
          <div class="case_top_item wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div :style="{ height: tempHeight ? tempHeight + 'px' : '' }" ref="caseTop"
              class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[0].id)" v-if="list2.length > 0">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 0">
                <img :src="list2[0].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 0">
                <div class="case_top_item_bor_btn_title">{{ list2[0].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[0].summary }}</div>
              </div>
            </div>
          </div>
          <!-- 右边三个 -->
          <div class="case_top_items wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[1].id)" v-if="list2.length > 1">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 1">
                <img :src="list2[1].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 1">
                <div class="case_top_item_bor_btn_title">{{ list2[1].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[1].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[2].id)" v-if="list2.length > 2">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 2">
                <img :src="list2[2].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 2">
                <div class="case_top_item_bor_btn_title">{{ list2[2].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[2].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_2 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[3].id)" v-if="list2.length > 3">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 3">
                <img :src="list2[3].list_image2" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 3">
                <div class="case_top_item_bor_btn_title">{{ list2[3].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[3].summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="case_top_box">
          <!-- 右边三个 -->
          <div class="case_top_items wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[4].id)" v-if="list2.length > 4">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 4">
                <img :src="list2[4].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 4">
                <div class="case_top_item_bor_btn_title">{{ list2[4].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[4].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[5].id)" v-if="list2.length > 5">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 5">
                <img :src="list2[5].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 5">
                <div class="case_top_item_bor_btn_title">{{ list2[5].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[5].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_2 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[6].id)" v-if="list2.length > 6">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 6">
                <img :src="list2[6].list_image2" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 6">
                <div class="case_top_item_bor_btn_title">{{ list2[6].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[6].summary }}</div>
              </div>
            </div>
          </div>
          <!-- 左边一个 -->
          <div class="case_top_item wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div :style="{ height: tempHeight ? tempHeight + 'px' : '100px' }" ref="caseTop"
              class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[7].id)" v-if="list2.length > 7">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 7">
                <img :src="list2[7].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 7">
                <div class="case_top_item_bor_btn_title">{{ list2[7].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[7].summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="case_top_box">
          <!-- 左边一个 -->
          <div class="case_top_item wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div :style="{ height: tempHeight ? tempHeight + 'px' : '100px' }" ref="caseTop"
              class="case_top_item_bor wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[8].id)" v-if="list2.length > 8">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 8">
                <img :src="list2[8].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 8">
                <div class="case_top_item_bor_btn_title">{{ list2[8].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[8].summary }}</div>
              </div>
            </div>
          </div>
          <!-- 右边三个 -->
          <div class="case_top_items wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[9].id)" v-if="list2.length > 9">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 9">
                <img :src="list2[9].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 9">
                <div class="case_top_item_bor_btn_title">{{ list2[9].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[9].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[10].id)" v-if="list2.length > 10">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 10">
                <img :src="list2[10].list_image1" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 10">
                <div class="case_top_item_bor_btn_title">{{ list2[10].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[10].summary }}</div>
              </div>
            </div>
            <div class="case_top_item_bor_2 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
              :data-wow-offset="height_top" @click="toDetails(list2[11].id)" v-if="list2.length > 11">
              <div class="case_top_item_bor_img_box" v-if="list2.length > 11">
                <img :src="list2[11].list_image2" class="case_top_item_bor_img" alt />
              </div>
              <div class="case_top_item_bor_btn" v-if="list2.length > 11">
                <div class="case_top_item_bor_btn_title">{{ list2[11].title }}</div>
                <div class="case_top_item_bor_btn_txt">{{ list2[11].summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页器 -->
        <div class="paginations">
          <el-pagination prev-text="上一页" next-text="下一页" background :pager-count="7" @current-change="handleCurrentChange"
            :current-page="current" :page-size="size" :page-count="total" :total="total"
            layout="slot,prev, pager, next,slot"></el-pagination>
          <div class="paginations_r"></div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import { adlist, articlelist4, projects } from "@/api/axios/axios.api";
import storage from "@/utils/storage";

import { WOW } from "wowjs";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      height_top: storage.height_top,
      isCarousel: false,
      tabIndex: null,
      type_id: "",
      tempHeight: "",
      timer: null,
      list: [],
      list1: [],
      list2: [],

      isActive: 1,
      // 分页
      size: 9, //每页显示个数
      current: 1, //当前显示页
      total: 9 //总数
    };
  },
  methods: {
    // 轮播图上一页
    prevs() {
      this.$refs.carousel.prev();
    },
    // 轮播图下一页
    nexts() {
      this.$refs.carousel.next();
    },
    carouselMouseover() {
      this.isCarousel = true;
    },
    carouselMouseout() {
      this.isCarousel = false;
    },
    // 类型
    typeClick(index, id) {
      this.tabIndex = index;
      this.type_id = id;
      this.getList();
    },
    // 详情
    toDetails(id) {
      this.$router.push({
        name: "sucsessdetails",
        query: {
          id: id
        }
      });
    },

    // 设置高度
    height() {
      //进入页面元素默认宽度
      this.timer = setInterval(() => {
        if (!this.$refs.caseTop) {
          this.tempHeight = this.$refs.caseTop.offsetWidth;
          clearInterval(this.timer)
          this.timer = null
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 0);
      //根据屏幕缩放自动获取页面宽度
      window.onresize = () => {
        return (() => {
          //窗口缩放自动获取元素宽度
          this.tempHeight = this.$refs.caseTop.offsetWidth; //宽度
        })();
      };
    },

    // 分页器
    handleCurrentChange(e) {
      this.current = e;
      this.getList();
    },
    // 获取数据
    async getData() {
      // 轮播图
      let rec = await adlist({ pid: 2 });
      this.list = rec.data.list;
      // 案例类型
      let res = await articlelist4();
      res.data.list.forEach(item => {
        item.list.forEach(i => {
          this.list1.push(i);
        });
      });
      // this.type_id = this.list1[0].id;
      this.getList();
    },
    // 获取列表
    async getList() {
      let res = await projects(this.type_id, this.current);
      this.total = Number(res.data.count);
      this.size = Number(res.data.limit);
      this.list2 = res.data.list;
      if (res.data.list.length) {
        this.height();
      }
    }
  },
  created() { },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      let wow = new WOW({
        live: true
      });
      wow.init();
    });
  },
  watch: {},

  beforeDestroy() {
    console.log("销毁定时器");
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .banner {
    color: #fff;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 520px;

    .banner_img {
      position: absolute;
      height: 520px;
      width: 100%;
    }

    /deep/ .el-carousel__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .banner_box {
      position: relative;
      padding: 0 10%;
      display: flex;
      // margin-top: 143px;

      .banner_box_l {
        width: 550px;
        display: flex;
        flex-direction: column;

        .banner_title {
          font-size: 30px;
          font-weight: 700;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_txt {
          line-height: 30px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .banner_btn {
          opacity: 1;

          .el-button {
            background: transparent;
            margin-top: 40px;
            width: 50px;
            height: 50px;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: all 0.5s;

            .banner_btn_img {
              width: 18px;
              height: 20px;
            }
          }

          .el-button:hover {
            transform: scale(1.2);
            background-color: #000;
            transition: all 0.5s;
          }
        }

        .banner_btns {
          opacity: 0;

          .el-button {
            background: transparent;
            transition: all 0.5s;

            .banner_btn_img {
              width: 18px;
              height: 20px;
            }
          }
        }
      }

      .banner_box_r {
        position: absolute;
        top: 0;
        left: calc(50% - 30px);
        font-size: 34px;
        text-align: center;
        line-height: 48px;
      }
    }
  }

  // 类型
  .type {
    display: flex;
    padding: 30px 10%;

    .type_l {
      width: 100px;
      font-size: 18px;
      color: #404d5b;
      font-weight: bold;
    }

    .type_r {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      color: #333333;

      .type_r_tab {
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }

      .type_r_active {
        color: #000;
        font-weight: bold;
        // font-size: 16px;
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }

      .type_r_tab:hover {
        color: #000;
        font-weight: bold;
        // font-size: 16px;
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  }

  // 列表
  .case {
    background-color: #fff;
    padding: 30px 10% 0 10%;
    box-sizing: border-box;
    box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);

    .case_top_box {
      display: flex;
      align-content: space-around;
      justify-content: space-between;

      .case_top_item {
        width: 49%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2%;

        .case_top_item_bor {
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          transition: all 0.5s;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_items {
        width: 49%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 2%;

        .case_top_item_bor_1 {
          width: 48%;
          height: calc(96% / 2);
          margin-bottom: 4%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;
          transition: all 0.5s;
          box-sizing: border-box;
          cursor: pointer;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_2 {
          width: 100%;
          height: calc(96% / 2);
          margin-bottom: 4%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;
          box-sizing: border-box;
          transition: all 0.5s;
          cursor: pointer;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor_2:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor_2:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }
    }

    .case_top_box:nth-child(3) .case_top_item {
      margin-bottom: 0;
    }

    .case_top_box:nth-child(3) .case_top_items {
      margin-bottom: 0;
    }

    .case_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      .el-button {
        width: 380px;
        height: 60px;
        background: #000000;
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 16px;
        color: #ffffff;
      }
    }

    // 分页器
    .paginations {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 30px 0;
      margin: auto;

      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #000 !important;
        color: #fff !important;
      }

      .paginations_l {
        padding: 3px 15px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #dcdcdc;
        margin-right: 10px;
      }

      /deep/ .btn-prev {
        background-color: #fff;
        border: 1px solid #f5f5f5;
        color: #333;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-prev:hover {
        background-color: #000;
        border: 1px solid #f5f5f5;
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-next {
        color: #333;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-next:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .el-pager li {
        color: #333;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .el-pager li:hover {
        color: #fff;

        background-color: #000;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }
    }
  }
}
@media screen and (max-width : 1399px) {
  .banner {
    min-width: 1300px;
    color: #fff;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 400px;
    /deep/ .el-carousel__container{
      height: 400px !important;
    }
    .banner_img {
      position: absolute;
      height: 400px;
      width: 100%;
    }

    /deep/ .el-carousel__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .banner_box {
      position: relative;
      padding: 0 20px;
      display: flex;
      // margin-top: 143px;

      .banner_box_l {
        width: 550px;
        display: flex;
        flex-direction: column;

        .banner_title {
          font-size: 30px;
          font-weight: 700;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_txt {
          line-height: 30px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .banner_btn {
          opacity: 1;

          .el-button {
            background: transparent;
            margin-top: 40px;
            width: 50px;
            height: 50px;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: all 0.5s;

            .banner_btn_img {
              width: 18px;
              height: 20px;
            }
          }

          .el-button:hover {
            transform: scale(1.2);
            background-color: #000;
            transition: all 0.5s;
          }
        }

        .banner_btns {
          opacity: 0;

          .el-button {
            background: transparent;
            transition: all 0.5s;

            .banner_btn_img {
              width: 18px;
              height: 20px;
            }
          }
        }
      }

      .banner_box_r {
        position: absolute;
        top: 0;
        left: calc(50% - 30px);
        font-size: 34px;
        text-align: center;
        line-height: 48px;
      }
    }
  }

  // 类型
  .type {
    min-width: 1300px;
    display: flex;
    padding: 30px 20px;

    .type_l {
      width: 100px;
      font-size: 18px;
      color: #404d5b;
      font-weight: bold;
    }

    .type_r {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      color: #333333;

      .type_r_tab {
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }

      .type_r_active {
        color: #000;
        font-weight: bold;
        // font-size: 16px;
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }

      .type_r_tab:hover {
        color: #000;
        font-weight: bold;
        // font-size: 16px;
        margin-right: 25px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  }

  // 列表
  .case {
    min-width: 1300px;
    background-color: #fff;
    padding: 30px 20px 0 20px;
    box-sizing: border-box;
    box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);

    .case_top_box {
      display: flex;
      align-content: space-around;
      justify-content: space-between;

      .case_top_item {
        width: 49%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2%;

        .case_top_item_bor {
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          transition: all 0.5s;

          .case_top_item_bor_img_box {
            flex: 1;
            // width: 380px;
            // height: 234px;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            // width: 380px;
            // height: 234px;
            // max-width: calc(100% - 50px) / 3;
            // max-height: 234px;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }

      .case_top_items {
        width: 49%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 2%;

        .case_top_item_bor_1 {
          width: 48%;
          height: calc(96% / 2);
          margin-bottom: 4%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;
          transition: all 0.5s;
          box-sizing: border-box;
          cursor: pointer;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor_1:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor_1:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }

        .case_top_item_bor_2 {
          width: 100%;
          height: calc(96% / 2);
          margin-bottom: 4%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          position: relative;
          box-sizing: border-box;
          transition: all 0.5s;
          cursor: pointer;

          .case_top_item_bor_img_box {
            // width: 380px;
            // height: 557px;
            flex: 1;
            overflow: hidden;
          }

          .case_top_item_bor_img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          .case_top_item_bor_btn {
            padding: 21px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .case_top_item_bor_btn_title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 9px;
            }

            .case_top_item_bor_btn_txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .case_top_item_bor_2:hover {
          transition: all 0.5s;
          box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }

        .case_top_item_bor_2:hover .case_top_item_bor_img {
          transform: scale(1.2);
        }
      }
    }

    .case_top_box:nth-child(3) .case_top_item {
      margin-bottom: 0;
    }

    .case_top_box:nth-child(3) .case_top_items {
      margin-bottom: 0;
    }

    .case_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      .el-button {
        width: 380px;
        height: 60px;
        background: #000000;
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 16px;
        color: #ffffff;
      }
    }

    // 分页器
    .paginations {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 30px 0;
      margin: auto;

      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #000 !important;
        color: #fff !important;
      }

      .paginations_l {
        padding: 3px 15px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #dcdcdc;
        margin-right: 10px;
      }

      /deep/ .btn-prev {
        background-color: #fff;
        border: 1px solid #f5f5f5;
        color: #333;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-prev:hover {
        background-color: #000;
        border: 1px solid #f5f5f5;
        color: #fff;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-next {
        color: #333;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .btn-next:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .el-pager li {
        color: #333;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }

      /deep/ .el-pager li:hover {
        color: #fff;

        background-color: #000;
        border: 1px solid #f5f5f5;
        padding: 0 10px;
        border-radius: 5px;
      }
    }
  }
}
</style>
